import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {collection, doc, onSnapshot, query, setDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    AssetDeleted, AssetId, AssetProjectId, AssetType,
    AssetUserId, EditorStateEditId, EditorStateIdMappingEditId, EditorStateIdMappingUserId, EditorStateUserId,
    kAssetsCollectionName, kEditorStateCollectionName, kEditorStateIdMappingCollectionName, kLibrettoUserCollectionName,
    kTracksCollectionName,
    TrackDeleted,
    TrackId,
    TrackUserId
} from "../../utils/CollectionConstants";
import Typography from "@mui/material/Typography";
import {FetchContext} from "../../context/FetchContext";
import {ClientSideSuspense} from "@liveblocks/react";
import {LibrettoLiveblocksContext} from "../Editor/LibrettoLiveblocksContext";
import {ClipSize16And9RatioId, loadTranscriptForTrack, RefreshTokenAndRetry} from "../../utils/utils";
import {createSaveFillerWordRemovalTrims, createUndoAllFillerWordRemovalTrims} from "../Editor/Liveblocks";
import ModernEditorInner from "./ModernEditorInner";
import NewEditorTopbar from "../NewEditor/NewEditorTopbar";
import {RendleyService} from "../../editor/services/RendleyService";
import {getCanvasElement} from "../../editor/utils/dom/getCanvasElement";
import RendleyBridge from "../../editor/RendleyBridge";
import {TITLES_PATH_CDN} from "../../editor/config/config";
import {StockMediaService} from "../../editor/services/StockMediaService";
import {Engine} from "@rendley/sdk";
import {DialogContent} from "@mui/material";
import UploadContentCard from "../NewHome/UploadContentCard";
import Dialog from "@mui/material/Dialog";
import CreateSomethingCard from "./CreateSomethingCard";
import {isEqual} from "lodash-es";

const ModernEditorWrapper = () => {

    const {editId} = useParams();

    const [projectId, setProjectId] = useState(null);
    const [assetId, setAssetId] = useState(null);

    const userId = useContext(AuthContext).getUserId();

    // Get the edit state.
    useEffect(() => {
        const q = query(collection(db, kEditorStateCollectionName),
            where(EditorStateEditId, "==", editId),
            where(EditorStateUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newProjectId = null;
            let newAssetId = null;
            querySnapshot.forEach((doc) => {
                newProjectId = doc.data().projectId;
                newAssetId = doc.data().assetId;
            });
            setProjectId(newProjectId);
            setAssetId(newAssetId);
        });

        return () => unsubscribe();
    }, [editId, userId]);

    useEffect(() => {

        const initRendley = () => {
            if (!RendleyService.getEngine().isInitialized()) {
                Engine.getInstance().init({
                    display: {
                        width: 1920,
                        height: 1080,
                        backgroundColor: "#000000",
                        view: getCanvasElement(),
                    },
                    license: {
                        licenseName: "Libretto",
                        licenseKey: "7324FD51F1255979246B0001",
                    },
                })
            }
        }

        const initStockMediaService = () => {
            StockMediaService.getInstance().init({
                pexelsApiKey: "ZPSMxFlBYLs9JQmTeFmSkeXDFf9VjJqHSAJCk6y5RyDFGmXV9edrThpl",
                giphyApiKey: "cGA8JsUtlRseBgqdBYSCCsFxgLYlm3tu",
            });
        }

        const initRendleyService = () => {
            RendleyBridge.init({
                filtersPath: "",
                effectsPath: "",
                transitionsPath: "",
                titlesPath: TITLES_PATH_CDN,
            }).then(() => {
                console.log("Rendley service initialized");
            });
        }

        initRendley();
        initRendleyService();
        initStockMediaService();
    }, []);

    return (
        <Box>
            <ModernEditor projectId={projectId} assetId={assetId} editId={editId}/>
        </Box>
    )
}

const ModernEditor = ({assetId, editId, projectId}) => {
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const navigate = useNavigate();

    console.log("AssetId:", assetId, "EditId:", editId, "ProjectId:", projectId)

    const userId = authContext.getUserId();
    const [tracks, setTracks] = useState(new Map());
    const [tracksLoaded, setTracksLoaded] = useState(false);

    // Used when an edit is started without an associated asset. This will prompt user to upload an asset with which to associate the edit.
    const [showCreateSomethingDialog, setShowCreateSomethingDialog] = useState(false);


    const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);

    const [captionsEnabled, setCaptionsEnabled] = useState(false);
    const [videoPlayerVisible, setVideoPlayerVisible] = useState(true);

    const editorRef = useRef(null);
    const videoplayerRef = useRef(null);
    const compositionContainerRef = useRef(null);
    const canvasRef = useRef(null);
    const timelineEditorRef = useRef(null);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const orderOfTracks = [];
    const editSettings = new Map();

    const [trackIdSet, setTrackIdSet] = useState(() => new Set(orderOfTracks));
    const [waveformRefs, setWaveformRefs] = useState(() => orderOfTracks.map(() => React.createRef()));

    const [clipSize, setClipSize] = useState(() => {
        const initialSettings = editSettings.get("editSettings");
        return initialSettings && initialSettings.videoAspectRatio ? initialSettings.videoAspectRatio : ClipSize16And9RatioId;
    });

    // Maps mediaIds in rendley to (trackIds, AssetIds) of the items in Libretto.
    const [mediaIdToEntityIdMap, setMediaIdToEntityIdMap] = useState(new Map());

    // Maps (trackIds, AssetIds) in Libretto to mediaIds in Rendley.
    const [entityIdToMediaIdMap, setEntityIdToMediaIdMap] = useState(new Map());

    const createEntityIdMediaIdMapping = ({entityId, mediaId, entityType}) => {
        // Update the mapping in the state.
        setMediaIdToEntityIdMap((prevMapping) => {
            const newMapping = new Map(prevMapping);
            newMapping.set(mediaId, entityId);
            return newMapping;
        });

        // Update the mapping in storage.
        setEntityIdToMediaIdMap((prevMapping) => {
            const newMapping = new Map(prevMapping);
            newMapping.set(entityId, mediaId);
            return newMapping;
        });

        // Create the mapping in the database.
        const entityRef = doc(db, kEditorStateIdMappingCollectionName, editId);
        setDoc(entityRef, {
            entityId: entityId,
            editId: editId,
            userId: userId,
            entityType: entityType,
            mediaId: mediaId
        }).then(() => {
        });
    }

    useEffect(() => {
        if (editSettings && editSettings.get("editSettings")) {
            setClipSize(editSettings.get("editSettings").videoAspectRatio);
        }
    }, [editSettings]);

    useEffect(() => {
        if (projectId && !assetId) {
            setShowCreateSomethingDialog(true)
        }
        if (projectId && assetId) {
            setShowCreateSomethingDialog(false)
        }

    }, [projectId, assetId]);

    // Get the edit state mapping.
    useEffect(() => {
        const q = query(collection(db, kEditorStateIdMappingCollectionName),
            where(EditorStateIdMappingEditId, "==", editId),
            where(EditorStateIdMappingUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const mediaEntityMap = new Map();
            const entityMediaMap = new Map();

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const mediaId = data.mediaId;
                const entityId = data.entityId;

                if (mediaId && entityId) {
                    mediaEntityMap.set(mediaId, entityId);
                    entityMediaMap.set(entityId, mediaId);
                }
            });

            setMediaIdToEntityIdMap((prevMapping) => {
                if (!isEqual(prevMapping, mediaEntityMap)) {
                    return mediaEntityMap;
                }
                return prevMapping;
            });
            setEntityIdToMediaIdMap((prevMapping) => {
                if (!isEqual(prevMapping, entityMediaMap)) {
                    return entityMediaMap;
                }
                return prevMapping;
            });
        });

        return () => unsubscribe();
    }, [editId, userId]);

    console.log("EntityIdToMediaIdMap:", entityIdToMediaIdMap, "MediaIdToEntityIdMap:", mediaIdToEntityIdMap);

    const handleVideoPlayerPlayToggle = (time, play) => {

    }

    const getTrackForSelectedIndex = () => {
        return tracks.get(orderOfTracks[selectedTrackIndex]);
    }

    // Load the tracks.
    useEffect(() => {
        if (!userId) {
            return;
        }

        if (orderOfTracks === undefined || orderOfTracks === null) {
            return;
        }
        if (orderOfTracks.length === 0) {
            setTracksLoaded(true);
            return;
        }

        const filterList = orderOfTracks && orderOfTracks.length > 0 ? orderOfTracks : [];

        const q = query(collection(db, kTracksCollectionName),
            where(TrackId, "in", filterList),
            where(TrackDeleted, "==", false),
            where(TrackUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newTracks = [];
            querySnapshot.forEach((doc) => {
                newTracks.push(doc.data());
            });
            // Set the tracks to the tracks state map keyed by trackId
            setTracks(new Map(newTracks.map(track => [track.trackId, track])));
            setTracksLoaded(true);
        });

        return () => unsubscribe();
    }, [orderOfTracks, userId]);


    const saveAllFillerWordTrims = () => {
    }

    const undoAllFillerWordTrims = () => {
    }


    const handleRemoveFillerWordsRequest = async (removeFillerWords) => {
        if (removeFillerWords) {
            if (editorRef.current) {
                const fillerWordTrims = editorRef.current.getFillerWordTrims();
                saveAllFillerWordTrims(fillerWordTrims);
            }
        } else {
            undoAllFillerWordTrims(orderOfTracks.length);
        }
    }

    const pageStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: "#F3F4F5",
        paddingTop: "20px",
        paddingLeft: "32px",
        paddingRight: "32px",
        height: "100vh",
        width: "100vw",
        gap: "28px",
        overflow: 'hidden',
    }

    const handleNavigateBack = () => {
        if (assetId) {
            navigate(`/assets/${assetId}`);
            window.location.reload();
        } else {
            navigate(`/dashboard`);
            window.location.reload();
        }
    }

    return (
        <Box sx={pageStyle}>
            <NewEditorTopbar selectedTrackIndex={selectedTrackIndex} containerRef={compositionContainerRef}
                             editId={editId}
                             canvasRef={canvasRef} handleNavigateBack={handleNavigateBack}
                             track={getTrackForSelectedIndex()} noAudioStream={true}
                             handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest}/>
            <Dialog
                open={showCreateSomethingDialog}
                onClose={() => {
                }}
                aria-labelledby="form-dialog-title"
                maxWidth={"1000px"}
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    padding: "0px 0px 0px 0px",
                }}>
                    <CreateSomethingCard projectId={projectId} editId={editId}/>
                </DialogContent>
            </Dialog>
            <ModernEditorInner mediaIdToEntityIdMap={mediaIdToEntityIdMap}
                               compositionContainerRef={compositionContainerRef}
                               canvasRef={canvasRef}
                               projectId={projectId}
                               handleSelect={() => console.log("Selected")} editorRef={editorRef}
                               timelineEditorRef={timelineEditorRef}
                               captionsEnabled={captionsEnabled} videoplayerRef={videoplayerRef}
                               videoPlayerVisible={videoPlayerVisible}
                               clipSize={clipSize}
                               waveformRefs={waveformRefs}
                               entityIdToMediaIdMap={entityIdToMediaIdMap}
                               createEntityIdMediaIdMapping={createEntityIdMediaIdMapping}
                                assetId={assetId}
                               loadTracks={() => {
                               }}/>
        </Box>
    )
}

export default ModernEditorWrapper;

