import { ClipTypeEnum, Engine, EventPayloadMap, EventsEnum, TextClip } from "@rendley/sdk";
import { Clip, Layer, MediaData, MediaDataStatusEnum, RendleyStore, Transition } from "./store/RendleyStore";
import { ApplicationStore } from "./store/ApplicationStore";
import { arrayToMap } from "./modules/timeline/utils/convertArrayToMap";
import { updateAdjacency } from "./modules/timeline/utils/updateLayerClipsAdjacency";
import { RendleyService } from "./services/RendleyService";
// @ts-ignore
import { fontSources } from "./config/fonts.config";
// import { fontSources } from "./config/fonts.config";

type EventPayload<T extends EventsEnum> = EventPayloadMap[T];

class RendleyBridge {
    readonly engine = Engine.getInstance();

    constructor() {
        this.setupEventListeners();
    }

    async init(options: { transitionsPath: string; filtersPath: string; effectsPath: string; titlesPath: string }) {
        try {
            ApplicationStore.setTitlesPath(options.titlesPath);

            // Load fonts in the background
            Promise.all(
                fontSources.map(async ({ family, cssUrl }) => {
                    await this.engine.getFontRegistry().loadFromCssUrl(family, cssUrl);
                }),
            );

            const transitionsRoot = options.transitionsPath;
            const filtersRoot = options.filtersPath;
            const effectsRoot = options.effectsPath;

            // Get transition descriptions
            fetch(transitionsRoot + "transitions.json")
                .then((data) => data.json())
                .then((data) => {
                    const transitions = data.transitions.map((transition: any) => {
                        return {
                            id: transition.id,
                            label: transition.name,
                            thumbnailUrl: transitionsRoot + transition.path + "thumbnail.webp",
                            videoPreviewUrl: transitionsRoot + transition.path + "preview.mp4",
                            shaderUrl: transitionsRoot + transition.path + "shader.glsl",
                        };
                    });

                    ApplicationStore.setTransitions(arrayToMap(transitions, "id"));
                });

            // Get filter list
            fetch(filtersRoot + "filters.json")
                .then((data) => data.json())
                .then((data) => {
                    const filters = data.filters.map((filter: any) => {
                        return {
                            id: filter.id,
                            label: filter.name,
                            thumbnailUrl: filtersRoot + filter.path + "thumbnail.webp",
                            lutUrl: filtersRoot + filter.path + "lut.png",
                        };
                    });

                    ApplicationStore.setFilters(arrayToMap(filters, "id"));
                });

            // Get Effect list
            fetch(effectsRoot + "effects.json")
                .then((data) => data.json())
                .then((data) => {
                    const effects = data.effects.map((effect: any) => {
                        return {
                            id: effect.id,
                            label: effect.name,
                            thumbnailUrl: effectsRoot + effect.path + "thumbnail.webp",
                            videoPreviewUrl: effectsRoot + effect.path + "preview.mp4",
                            shaderUrl: effectsRoot + effect.path + "shader.glsl",
                        };
                    });

                    ApplicationStore.setEffects(arrayToMap(effects, "id"));
                });
        } finally {
            ApplicationStore.setIsLoading(false);
        }

        // TODO: Remove on production!
        (window as any).engine = this.engine;
    }

    private setupEventListeners() {
        const events = this.engine.events;

        events.on(EventsEnum.PLAYING, this.handlePlaying);
        events.on(EventsEnum.TIME, this.handleTime);
        events.on(EventsEnum.READY, this.handleReady);
        events.on(EventsEnum.DISPLAY_BACKGROUND_UPDATED, this.handleBackgroundUpdated);
        events.on(EventsEnum.DISPLAY_RESOLUTION_UPDATED, this.handleResolutionUpdated);
        events.on(EventsEnum.LAYER_ADDED, this.handleLayerAdded);
        events.on(EventsEnum.LAYER_REMOVED, this.handleLayerRemoved);
        events.on(EventsEnum.LAYER_UPDATED, this.handleLayerUpdated);
        events.on(EventsEnum.LAYERS_ORDER_UPDATED, this.handleLayersOrderUpdated);
        events.on(EventsEnum.TRANSITION_ADDED, this.handleAddTransition);
        events.on(EventsEnum.TRANSITION_REMOVED, this.handleRemoveTransition);
        events.on(EventsEnum.CLIP_ADDED, this.handleClipAdded);
        events.on(EventsEnum.CLIP_REMOVED, this.handleClipRemoved);
        events.on(EventsEnum.CLIP_UPDATED, this.handleClipUpdated);
        events.on(EventsEnum.CLIP_UPDATED_TEXT, this.handleClipUpdatedText);
        events.on(EventsEnum.CLIP_STYLE_UPDATED, this.handleClipStyleUpdated);
        events.on(EventsEnum.CLIP_EFFECT_ADDED, this.handleClipEffectAdded);
        events.on(EventsEnum.CLIP_EFFECT_REMOVED, this.handleClipEffectRemoved);
        events.on(EventsEnum.CLIP_FILTER_ADDED, this.handleClipFilterAdded);
        events.on(EventsEnum.CLIP_FILTER_REMOVED, this.handleClipFilterRemoved);
        events.on(EventsEnum.CLIP_ERROR, this.handleClipError);
        events.on(EventsEnum.LIBRARY_ADDED, this.handleLibraryAdded);
        events.on(EventsEnum.LIBRARY_REMOVED, this.handleLibraryRemoved);
        events.on(EventsEnum.LIBRARY_ERROR, this.handleLibraryError);
        events.on(EventsEnum.LIBRARY_MEDIA_UPDATED, this.handleLibraryMediaUpdated);
        events.on(EventsEnum.LIBRARY_REPLACED, this.handleLibraryMediaReplaced);
    }

    private handleReady = () => {
        const timeline = this.engine.getTimeline();
        const display = this.engine.getDisplay();
        const resolution = display.getResolution();

        const media = Object.values(this.engine.getLibrary().media).reduce<Record<string, MediaData>>((acc, cv) => {
            const data = {
                id: cv.getId(),
                thumbnail: cv.thumbnail,
                type: cv.type,
                filename: cv.filename,
                duration: cv.duration,
                status: MediaDataStatusEnum.READY,
            };

            acc[data.id] = data;
            return acc;
        }, {});

        const clips = timeline.getClips().reduce<Record<string, Clip>>((acc, cv) => {
            const data: Clip = {
                id: cv.id,
                type: cv.getType() as ClipTypeEnum,
                startTime: cv.getStartTime(),
                leftTrim: cv.getLeftTrim(),
                rightTrim: cv.getRightTrim(),
                duration: cv.getDuration(),
                trimmedDuration: cv.getTrimmedDuration(),
                mediaDataId: cv.getMediaId(),
                text: cv instanceof TextClip ? cv.text : "",
            };

            acc[data.id] = data;
            return acc;
        }, {});

        const layers = Object.values(timeline.layers).reduce<Record<string, Layer>>((acc, cv) => {
            const data: Layer = {
                id: cv.id,
                clipsIds: cv.clipsIds,
                transitionIds: cv.transitions.map((t) => t.id),
            };

            acc[data.id] = data;
            return acc;
        }, {});

        const transitions = Object.values(timeline.layers)
            .map((layer) => Object.values(layer.transitions))
            .flat()
            .reduce<Record<string, Transition>>((acc, cv) => {
                const data: Transition = {
                    id: cv.id,
                    startClipId: cv.startClip.id,
                    endClipId: cv.endClip.id,
                    inDuration: cv.inDuration,
                    outDuration: cv.outDuration,
                };

                acc[data.id] = data;
                return acc;
            }, {});

        const styles = timeline.getClips().reduce<Record<string, any>>((acc, cv) => {
            const data = cv.style && Object.keys(cv.style).length > 0 ? cv.style.serialize() : undefined;

            acc[cv.id] = data;
            return acc;
        }, {});

        ApplicationStore.setSelectedTransitionId(null);

        RendleyStore.setIsPlaying(false);
        RendleyStore.setCurrentTime(timeline.currentTime);
        RendleyStore.setDisplayResolution(resolution[0], resolution[1]);
        RendleyStore.setDisplayBackgroundColor(display.getBackgroundColor());
        RendleyStore.setMedia(media);
        RendleyStore.setClips(clips);
        RendleyStore.setLayers(layers);
        RendleyStore.setLayersOrder(timeline.layersOrder);
        RendleyStore.setTransitions(transitions);
        RendleyStore.setStyles(styles);
        RendleyStore.setUpdateTimestamp(Date.now());
        RendleyStore.updateTimelineDuration();

        RendleyStore.layersOrder.forEach((layerId) => {
            updateAdjacency(layerId);
        });
    };

    private handlePlaying = (payload: EventPayload<EventsEnum.PLAYING>) => {
        RendleyStore.setIsPlaying(payload.isPlaying);
    };

    private handleTime = (currentTime: number) => {
        const fitDuration = RendleyService.getEngine().getTimeline().getFitDuration();

        if (currentTime >= fitDuration && RendleyStore.isPlaying) {
            RendleyService.pause();
            RendleyService.seek(fitDuration);
            return;
        }

        RendleyStore.setCurrentTime(currentTime);
    };

    private handleBackgroundUpdated = (payload: EventPayload<EventsEnum.DISPLAY_BACKGROUND_UPDATED>) => {
        RendleyStore.setDisplayBackgroundColor(payload.backgroundColor);
    };

    private handleResolutionUpdated = (payload: EventPayload<EventsEnum.DISPLAY_RESOLUTION_UPDATED>) => {
        RendleyStore.setDisplayResolution(payload.width, payload.height);
    };

    private handleAddTransition = (payload: EventPayload<EventsEnum.TRANSITION_ADDED>) => {
        const layer = this.engine.getTimeline().getLayerById(payload.layerId);
        if (!layer) return;

        const transition = layer.transitions.find((tx) => tx.id === payload.transitionId);
        if (!transition) return;

        RendleyStore.addTransition(transition.serialize());

        RendleyStore.updateLayer(layer.id, {
            transitionIds: [...RendleyStore.layers[layer.id].transitionIds, transition.id],
        });
    };

    private handleRemoveTransition = (payload: EventPayload<EventsEnum.TRANSITION_REMOVED>) => {
        ApplicationStore.setSelectedTransitionId(null);
        RendleyStore.deleteTransition(payload.transitionId, payload.layerId);
    };

    private handleLayerUpdated = (payload: EventPayload<EventsEnum.LAYER_UPDATED>) => {
        this.handleLayerAdded(payload);

        const layer = this.engine.getTimeline().getLayerById(payload.layerId);
        if (!layer) return;

        RendleyStore.updateLayer(layer.id, {
            clipsIds: [...layer.clipsIds],
        });

        if (layer?.clipsIds?.length === 0) {
            RendleyService.deleteLayer(payload.layerId);
        }
    };

    private handleLayerAdded = (payload: EventPayload<EventsEnum.LAYER_ADDED>) => {
        const timeline = this.engine.getTimeline();
        const layer = timeline.layers[payload.layerId];
        if (!layer) return;

        RendleyStore.addLayer({
            id: layer.id,
            clipsIds: layer.clipsIds,
            transitionIds: layer.transitions.map((tx) => tx.id),
        });

        RendleyStore.setLayersOrder(timeline.layersOrder);
    };

    private handleLayerRemoved = (payload: EventPayload<EventsEnum.LAYER_REMOVED>) => {
        RendleyStore.deleteLayer(payload.layerId);
    };

    private handleLayersOrderUpdated = (payload: EventPayload<EventsEnum.LAYERS_ORDER_UPDATED>) => {
        RendleyStore.setLayersOrder(this.engine.getTimeline().layersOrder);
    };

    private handleClipUpdated = (payload: EventPayload<EventsEnum.CLIP_UPDATED>) => {
        const clip = Engine.getInstance().getClipById(payload.clipId);
        if (!clip) return;

        console.log("CLIP UPDATED")

        RendleyStore.updateClip(payload.clipId, {
            startTime: clip.getStartTime(),
            duration: clip.getDuration(),
            leftTrim: clip.getLeftTrim(),
            rightTrim: clip.getRightTrim(),
            trimmedDuration: clip.getTrimmedDuration(),
        });
    };

    private handleClipStyleUpdated = (payload: EventPayload<EventsEnum.CLIP_STYLE_UPDATED>) => {
        RendleyStore.updateStyles(payload.clipId, {
            [payload.property]: payload.value,
        });
    };

    private handleClipFilterAdded = (payload: EventPayload<EventsEnum.CLIP_FILTER_ADDED>) => {
        RendleyStore.addFilter(payload.clipId, {
            id: payload.filterId,
            sourceId: payload.sourceId,
        });
    };

    private handleClipFilterRemoved = (payload: EventPayload<EventsEnum.CLIP_FILTER_REMOVED>) => {
        RendleyStore.deleteFilter(payload.clipId, payload.filterId);
    };

    private handleClipEffectAdded = (payload: EventPayload<EventsEnum.CLIP_EFFECT_ADDED>) => {
        RendleyStore.addEffect(payload.clipId, {
            id: payload.effectId,
            sourceId: payload.sourceId,
        });
    };

    private handleClipEffectRemoved = (payload: EventPayload<EventsEnum.CLIP_EFFECT_REMOVED>) => {
        RendleyStore.deleteEffect(payload.clipId, payload.effectId);
    };

    private handleClipUpdatedText = (payload: EventPayload<EventsEnum.CLIP_UPDATED_TEXT>) => {
        RendleyStore.updateClip(payload.clipId, {
            text: payload.text,
        });
    };

    private handleClipAdded = (payload: EventPayload<EventsEnum.CLIP_ADDED>) => {
        const clip = this.engine.getTimeline().getClipById(payload.clipId);

        if (!clip) return;

        const clipInstance = Engine.getInstance().getClipById(payload.clipId);

        RendleyStore.setClip({
            id: clip.id,
            type: (clip as any).type,
            text: clip instanceof TextClip ? clip.text : "",
            startTime: clip.getStartTime(),
            leftTrim: clip.getLeftTrim(),
            rightTrim: clip.getRightTrim(),
            duration: clip.getDuration(),
            trimmedDuration: clip.getTrimmedDuration(),
            mediaDataId: clip.getMediaId(),
        });

        const styles = clipInstance?.style?.serialize?.();

        if (styles) {
            RendleyStore.updateStyles(clip.id, styles);
        }

        RendleyStore.updateTimelineDuration();
    };

    private handleClipRemoved = (payload: EventPayload<EventsEnum.CLIP_REMOVED>) => {
        const selectedClipId = ApplicationStore.selectedClipId;

        if (payload.clipId === selectedClipId) {
            ApplicationStore.setSelectedClipId(null);
        }

        RendleyStore.deleteClip(payload.clipId, payload.layerId);

        const layer = RendleyStore.layers[payload.layerId];

        if (layer?.clipsIds?.length === 0) {
            RendleyService.deleteLayer(payload.layerId);
        } else {
            updateAdjacency(payload.layerId);
        }
    };

    private handleLibraryAdded = (payload: EventPayload<EventsEnum.LIBRARY_ADDED>) => {
        const mediaData = this.engine.getLibrary().getMediaById(payload.mediaDataId);

        if (mediaData == null) {
            return;
        }

        RendleyStore.addMedia({
            id: mediaData.getId(),
            thumbnail: mediaData.thumbnail,
            type: mediaData.type,
            filename: mediaData.filename,
            duration: mediaData.duration,
            status: MediaDataStatusEnum.LOADING,
        });
    };

    private handleLibraryRemoved = (payload: EventPayload<EventsEnum.LIBRARY_REMOVED>) => {
        RendleyStore.deleteMedia(payload.mediaDataId);
    };

    private handleLibraryMediaUpdated = (payload: EventPayload<EventsEnum.LIBRARY_MEDIA_UPDATED>) => {
        if (payload.status === "ready") {
            this.handleLibraryMediaReplaced(payload);
        }
    };

    private handleLibraryMediaReplaced = (payload: EventPayload<EventsEnum.LIBRARY_REPLACED>) => {
        const mediaData = RendleyService.getMediaById(payload.mediaDataId);

        if (mediaData == null) {
            return;
        }

        RendleyStore.addMedia({
            id: mediaData.getId(),
            thumbnail: mediaData.thumbnail,
            type: mediaData.type,
            filename: mediaData.filename,
            duration: mediaData.duration,
            status: MediaDataStatusEnum.READY,
        });
    };

    private handleLibraryError = (payload: EventPayload<EventsEnum.LIBRARY_ERROR>) => {
        console.error("Library Error:", payload);
    };

    private handleClipError = (payload: EventPayload<EventsEnum.CLIP_ERROR>) => {
        console.error("Clip Error:", payload);
    };
}

export default new RendleyBridge();
