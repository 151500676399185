import {Box, DialogContent} from "@mui/material";
import {
    compareTracksForSort, formatToReadableLargeTitle,
    formatToReadableTitle,
    formatUnixTime, formatUnixTimeBrief, IsUploadedAssetType,
    LibrettoStudioTheme, PrepareRecordingTrackSet, PrepareRenderableRecordingTracks,
    RefreshTokenAndRetry, TypeOfTrack
} from "../../utils/utils";
import {StudioBackIconButton} from "../NewStudio/SettingsIcon";
import * as React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {EditButton, ShareButton, ThreeDotsButton} from "../NewHome/HomeButtons";
import {ProjectContextMenu} from "../NewHome/NewHome";
import NewShareProjectCard from "../NewHome/NewShareProjectCard";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    AssetId, AssetUserId,
    EditAssetId, EditUserId, kAssetsCollectionName,
    kEditsCollectionName,
    kTracksCollectionName,
    TrackAssetId,
    TrackDeleted,
    TrackUserId
} from "../../utils/CollectionConstants";
import AssetVideoPlayer from "./VideoPlayer";
import {DraftEditsCards, DraftEditsView} from "../Asset/Shared";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {ListViewRecordedTrackSet, ListViewUploadedTrack} from "../NewHome/NewAssetAndProjectCards";
import {CloseIcon, LogoIcon} from "../NewHome/HomeIcons";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import {RenameEntityCard} from "./AssetCards";
import {useConfirmDialog} from "../NewHome/DeleteConfirmation";
import {DownloadWebVttForTrack} from "../Editor/Transcript";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const pageStyle = {
    minHeight: "100vh",
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    background: "#F3F4F5",
    paddingTop: "20px",
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingBottom: "64px",
}

const navBarStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}

const titleStyle = {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    marginBottom: "20px",
};

export const AssetAndTrackDetails = ({assetTitle, assetCreatedAt, trackTitle, trackCreatedAt}) => {

    const areaStyle = {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
        position: "absolute",
        left: "32px",
        marginTop: "128px",
    }

    const boldStyle = {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        color: "#1A1A1A",
    }

    const assetTitleStyle = {
        ...boldStyle,
        fontSize: "20px",
    }

    const trackTitleStyle = {
        ...boldStyle,
        fontSize: "16px",
    }

    const dateTextStyle = {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        color: "#808080",
    }

    return (
        <Box sx={areaStyle}>
            <Box sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}>
                <Box sx={assetTitleStyle}>
                    {formatToReadableTitle(assetTitle)}
                </Box>
                <div> |</div>
                <Box sx={dateTextStyle}>
                    {formatUnixTimeBrief(assetCreatedAt)}
                </Box>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", gap: "5px"}}>
                <Box sx={trackTitleStyle}>
                    {formatToReadableLargeTitle(trackTitle)}
                </Box>
                <Box sx={dateTextStyle}>
                    {formatUnixTime(trackCreatedAt)}
                </Box>
            </Box>
        </Box>
    )
}

const NewAsset = () => {
    const navigate = useNavigate();

    const {assetId} = useParams();

    const fetchContext = useContext(FetchContext);
    const auth = useContext(AuthContext);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('smd'));

    const [threeDotsMenuOpen, setThreeDotsMenuOpen] = React.useState(false);
    const [threeDotsMenuTop, setThreeDotsMenuTop] = React.useState(0);
    const [threeDotsMenuRight, setThreeDotsMenuRight] = React.useState(0);
    const [showShareAssetCard, setShowShareAssetCard] = React.useState(false);
    const [tracks, setTracks] = useState([]);
    const [draftEdits, setDraftEdits] = useState([]);
    const [editSummaries, setEditSummaries] = useState(new Map());
    const [trackDataLoaded, setTrackDataLoaded] = useState(false);
    const [assetTranscriptId, setAssetTranscriptId] = useState("");
    const [assetTitle, setAssetTitle] = useState("");
    const [assetCreatedAt, setAssetCreatedAt] = useState(0);
    const [assetType, setAssetType] = useState("");
    const userId = useContext(AuthContext).getUserId();

    const [switchToComputerFormOpen, setSwitchToComputerFormOpen] = useState(isMobile);

    const {exportedEditedTracks, originalRecordings} = useMemo(() => {
        const exportedEdited = tracks.filter((track) => track.isEdit === true);
        const original = tracks.filter((track) => track.isEdit === null || track.isEdit === false);
        const prepared = IsUploadedAssetType(assetType)
            ? original
            : PrepareRecordingTrackSet({originalTracks: original, assetTitle});

        return {exportedEditedTracks: exportedEdited, originalRecordings: prepared};
    }, [tracks, assetType, assetTitle]);

    const [selectedTrackId, setSelectedTrackId] = useState(null);
    const [selectedTrack, setSelectedTrack] = useState(null);

    const handleTrackSelection = (trackId) => {
        const allTracks = [...exportedEditedTracks, ...originalRecordings];
        const selectedTrack = allTracks.find(track => track.trackId === trackId);
        setSelectedTrackId(trackId);
        setSelectedTrack(selectedTrack);
    };

    useEffect(() => {
        if (exportedEditedTracks.length > 0) {
            handleTrackSelection(exportedEditedTracks[0].trackId);
        } else if (originalRecordings.length > 0) {
            handleTrackSelection(originalRecordings[0].trackId);
        }
    }, [exportedEditedTracks, originalRecordings]);

    const [entityRenameCardOpen, setEntityRenameCardOpen] = useState(false);
    const [entityToRenameId, setEntityToRenameId] = useState([]);
    const [entityToRenameIsAsset, setEntityToRenameIsAsset] = useState(null);
    const [entityToRenameTitle, setEntityToRenameTitle] = useState(null);

    const handleThreeDotsButtonClick = (event) => {

        if (threeDotsMenuOpen) {
            setThreeDotsMenuOpen(false);
            return;
        }

        // Get the top and right position of the button
        const top = event.currentTarget.getBoundingClientRect().top;
        const right = event.currentTarget.getBoundingClientRect().right;
        // Set the position of the context menu
        setThreeDotsMenuTop(top);
        setThreeDotsMenuRight(right);
        setThreeDotsMenuOpen(true);
    }

    const handleRenameClick = () => {
        setEntityToRenameIsAsset(true);
        setEntityToRenameId([assetId]);
        setEntityToRenameTitle(assetTitle);
        setThreeDotsMenuOpen(false);
        setEntityRenameCardOpen(true);
    }

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Delete', cancelText: 'Cancel'});

    const [editButtonLoading, setEditButtonLoading] = useState(false);

    const deleteAsset = async () => {
        fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
            }
        }).then(() => {

        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, auth, fetchContext).then(() => {

                }).catch(() => {
                    auth.logout();
                })
            }
        })
    };

    const deleteDraftEdit = async ({editId}) => {
        try {
            const {data} = await fetchContext.authAxios.delete(`/edit/${editId}`, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                },
            });
            return data.editId;
        } catch (error) {
            if (error.response.status === 401) {
                await auth.logout();
            }
        }
    }

    const deleteTracks = async ({numAllTracks, trackIds}) => {
        fetchContext.authAxios.post(`/tracks/delete`, {
            trackIds: trackIds,
        }, {
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
            }
        }).then(() => {
            if (numAllTracks === 1) {
                navigate("/dashboard")
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, auth, fetchContext);
            }
        })
    }

    const handleDeleteClick = () => {
        openDialog({
            title: 'Delete this recording?',
            message: 'All of its tracks and edits will be permanently deleted.',
            onConfirm: async () => {
                await deleteAsset();
            },
        });
    }

    const handleDeleteDraftClick = (editId) => {
        openDialog({
            title: 'Delete this draft edit?',
            message: 'It will be permanently deleted.',
            onConfirm: async () => {
                await deleteDraftEdit({editId});
            },
        });
    }

    const handleDeleteTrackClick = (trackIds) => {
        openDialog({
            title: 'Delete this track?',
            message: 'It will be permanently deleted.',
            onConfirm: async () => {
                await deleteTracks({numAllTracks: tracks.length, trackIds: trackIds});
            },
        });
    }

    const handleShareButtonClick = (e) => {
        e.stopPropagation();
        // e is a click on a button. Get the right position of the button
        setShowShareAssetCard(!showShareAssetCard);
    }

    const CreateEditForTrack = async ({trackId}) => {
        try {
            const {data} = await fetchContext.authAxios.get(`/edit/${trackId}`, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                },
            });
            return data.editId;
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    }

    const CreateEditAndNavigateToEditor = () => {
        setEditButtonLoading(true);
        CreateEditForTrack({trackId: selectedTrackId}).then((editId) => {
            navigate(`/editor/${assetId}/${editId}`);
            setEditButtonLoading(false);
        }).catch((error) => console.error("Error creating edit: ", error));
    }

    const videoPlayerArea = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: "78px",
    }

    // Get all tracks for this asset.
    useEffect(() => {
        if (!userId || !assetId) {
            return;
        }
        const q = query(collection(db, kTracksCollectionName),
            where(TrackAssetId, "==", assetId),
            where(TrackDeleted, "==", false),
            where(TrackUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newTracks = [];
            querySnapshot.forEach((doc) => {
                newTracks.push(doc.data());
            });
            if (newTracks.length > 0) {
                setTrackDataLoaded(true);
            }
            // Sort assets by title, keeping the track with title "All participants" at the beginning.
            newTracks.sort(compareTracksForSort);
            setTracks(newTracks);
        });

        return () => unsubscribe();
    }, [assetId, userId]);

    // Load all edits for this asset.
    useEffect(() => {
        const q = query(collection(db, kEditsCollectionName),
            where(EditAssetId, "==", assetId),
            where(EditUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newDraftEdits = [];
            querySnapshot.forEach((doc) => {
                newDraftEdits.push(doc.data());
            });
            setDraftEdits(newDraftEdits);
        });

        return () => unsubscribe();
    }, [assetId, userId]);

    // Load renderable edit summaries for this asset.
    useEffect(() => {
        const getEditSummaries = async () => {
            try {
                const {data} = await fetchContext.authAxios.get(`/assets/edits/${assetId}`, {
                    headers: {
                        Authorization: `Bearer ${auth.getToken()}`,
                    },
                });
                return data;
            } catch (error) {
                if (error.response.status === 401) {
                    await RefreshTokenAndRetry(error, auth, fetchContext);
                }
            }
        }

        const editSummariesMap = new Map();
        if (assetId) {
            getEditSummaries().then((data) => {
                for (const [editId, summary] of Object.entries(data)) {
                    editSummariesMap.set(editId, summary);
                }
                setEditSummaries(editSummariesMap);
            });
        }

    }, [assetId]);


    // Get the asset attributes.
    useEffect(() => {
        const q = query(collection(db, kAssetsCollectionName),
            where(AssetId, "==", assetId),
            where(AssetUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newAssetThumbnailId = "";
            let newAssetTitle = "";
            let newAssetCreatedAt = 0;
            let newAssetType = "";
            querySnapshot.forEach((doc) => {
                newAssetThumbnailId = doc.data().transcriptId;
                newAssetTitle = doc.data().title;
                newAssetCreatedAt = doc.data().createTime;
                newAssetType = doc.data().assetType;
            });
            setAssetTranscriptId(newAssetThumbnailId);
            setAssetTitle(newAssetTitle);
            setAssetCreatedAt(newAssetCreatedAt);
            setAssetType(newAssetType);
        });

        return () => unsubscribe();
    }, [assetId, userId]);

    const handleRenameTrackClick = ({trackIds, title}) => {
        setEntityToRenameIsAsset(false);
        setEntityToRenameId(trackIds);
        setEntityToRenameTitle(title);
        setEntityRenameCardOpen(true);
    }

    const handleCardClick = (item) => {
        handleTrackSelection(item.trackId)
    }

    const handleDownloadTranscriptClick = async ({trackId, title}) => {
        await DownloadWebVttForTrack(trackId, title, fetchContext, auth);
    }

    return (
        <Box sx={pageStyle}>
            <Box sx={navBarStyle}>
                <Box sx={{}}>
                    <StudioBackIconButton theme={LibrettoStudioTheme.Light}
                                          handleClick={() => navigate("/dashboard")}/>
                </Box>
                <Box style={{flexDirection: "row", gap: "12px", display: "flex"}}>
                    <Box>
                        <ThreeDotsButton onClick={handleThreeDotsButtonClick}/>
                    </Box>
                    {threeDotsMenuOpen &&
                        <ProjectContextMenu setThreeDotsMenuOpen={setThreeDotsMenuOpen} top={threeDotsMenuTop}
                                            right={threeDotsMenuRight} handleRenameClick={handleRenameClick}
                                            handleDeleteClick={handleDeleteClick} isAsset={true}/>}
                    <Dialog
                        open={entityRenameCardOpen}
                        onClose={() => setEntityRenameCardOpen(false)}
                        aria-labelledby="form-dialog-title"
                    >
                        <RenameEntityCard onClose={() => setEntityRenameCardOpen(false)} isAsset={entityToRenameIsAsset}
                                          fetchContext={fetchContext} authContext={auth}
                                          currentEntityTitle={entityToRenameTitle} entityId={entityToRenameId}/>
                    </Dialog>
                    <ConfirmDialogComponent/>
                    <Box>
                        <ShareButton onClick={handleShareButtonClick}/>
                    </Box>
                    {showShareAssetCard && <NewShareProjectCard entityId={assetId}
                                                                onClose={() => setShowShareAssetCard(false)}
                                                                isAsset={true}/>}
                    <Box>
                        <EditButton onClick={CreateEditAndNavigateToEditor} loading={editButtonLoading}/>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={isMobile}
                onClose={() => setSwitchToComputerFormOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: {xs: '300px', md: '400px'},
                }}>
                    <Typography><b>Please switch to your computer.</b> For now, you need a larger screen to view
                        this.</Typography>
                </DialogContent>
            </Dialog>
            <Box sx={videoPlayerArea}>
                <video controls={true} style={{height: "55%", maxHeight: "550px", width: "60%", maxWidth: "1000px", borderRadius: "20px"}}
                    src={selectedTrack ? selectedTrack.renditionUrl ? selectedTrack.renditionUrl : selectedTrack.objectUrl : ""}
                />
            </Box>
            <AssetAndTrackDetails assetTitle={assetTitle} assetCreatedAt={assetCreatedAt}
                                  trackTitle={selectedTrack ? selectedTrack.title : ""}
                                  trackCreatedAt={selectedTrack ? selectedTrack.createTime : ""}/>
            {draftEdits.length > 0 && editSummaries.size > 0 &&
                <Box sx={{marginTop: "60px"}}>
                    <DraftEditsCards draftEdits={draftEdits} editSummaries={editSummaries}
                                     deleteEdit={handleDeleteDraftClick}
                                     assetId={assetId} navigate={navigate}/>
                </Box>}
            {exportedEditedTracks.length > 0 && !isMobile &&
                <Box sx={{marginTop: "40px"}}>
                    <Box style={titleStyle}>
                        Exported edits
                    </Box>
                    <Grid item>
                        {exportedEditedTracks.map((item, i) => (
                            <ListViewUploadedTrack track={item} onClick={() => handleCardClick(item)}
                                                   onDownloadTranscript={handleDownloadTranscriptClick}
                                                   onRenameClick={handleRenameTrackClick}
                                                   onDeleteClick={handleDeleteTrackClick}
                                                   isSelected={item.trackId === selectedTrackId}/>
                        ))}
                    </Grid>
                </Box>}
            {originalRecordings.length > 0 && !isMobile &&
                <OriginalTracksView originalTracks={originalRecordings} onClick={handleCardClick} assetType={assetType}
                                    assetTitle={assetTitle} onDownloadTranscript={handleDownloadTranscriptClick}
                                    onRenameClick={handleRenameTrackClick} onDeleteClick={handleDeleteTrackClick}
                                    selectedTrackId={selectedTrackId}/>}
        </Box>
    )
}

export const OriginalTracksView = ({
                                       originalTracks,
                                       assetType,
                                       assetTitle,
                                       onRenameClick,
                                       onDownloadTranscript,
                                       onDeleteClick,
                                       onClick,
                                       selectedTrackId,
                                       isSharedView
                                   }) => {

    if (IsUploadedAssetType(assetType)) {

        if (originalTracks.length === 0) {
            return null;
        }

        return (
            <Box sx={{marginTop: "40px"}}>
                {assetType === "SCREEN_RECORDING" ?
                    <Box style={titleStyle}>
                        Recording files
                    </Box> : <Box style={titleStyle}>
                        Uploaded files
                    </Box>}
                <Grid item>
                    {originalTracks.map((item, i) => (
                        <ListViewUploadedTrack track={item} onClick={onClick}
                                               onDownloadTranscript={onDownloadTranscript} onRenameClick={onRenameClick}
                                               onDeleteClick={onDeleteClick}
                                               isSelected={selectedTrackId === item.trackId}
                                               isSharedView={isSharedView}/>
                    ))}
                </Grid>
            </Box>
        )
    }

    const renderableRecordings = originalTracks;

    if (renderableRecordings.length === 0) {
        return null;
    }

    const headTitleStyle = {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        marginBottom: "12px",
    };

    const subtitleStyle = {
        color: "#808080",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        marginBottom: "20px",
    }

    return (
        <Box sx={{marginTop: "40px"}}>
            <Box style={headTitleStyle}>
                Recording files
            </Box>
            <Box style={subtitleStyle}>
                The individual tracks of the recording.
            </Box>
            <Grid item>
                {renderableRecordings.map((item, i) => (
                    <ListViewRecordedTrackSet trackSet={item} onClick={onClick}
                                              onDownloadTranscript={onDownloadTranscript} onRenameClick={onRenameClick}
                                              onDeleteClick={onDeleteClick}
                                              isSelected={selectedTrackId === item.trackId}
                                              isSharedView={isSharedView}/>
                ))}
            </Grid>
        </Box>
    )
}

const logoBoxStyle = {
    display: "flex",
    padding: "20px 12px",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch",
    marginBottom: "24px",
    cursor: "pointer",
}

const announcementBarStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '18px',
    backgroundColor: '#2B6BFD', // You can change this to match your theme
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above other elements
    overflow: 'hidden',
    transition: 'height 0.3s ease',
    '&:hover': {
        height: '20px', // Expands on hover for better readability
    }
};

const announcementTextStyle = {
    color: 'white',
    fontSize: '13px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const NewSharedAsset = () => {
    const navigate = useNavigate();

    const {assetId, token} = useParams();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('smd'));

    const [switchToComputerFormOpen, setSwitchToComputerFormOpen] = useState(isMobile);

    const fetchContext = useContext(FetchContext);

    const [assetData, setAssetData] = useState({
        error: null,
        isLoaded: false,
        title: '',
        type: '',
        createTime: 0,
        tracks: []
    });
    const {error, isLoaded, title, createTime, tracks, type} = assetData;


    const {exportedEditedTracks, originalRecordings} = useMemo(() => {
        const exportedEdited = tracks.filter((track) => track.isEdit === true);
        const original = tracks.filter((track) => track.isEdit === null || track.isEdit === false);
        const prepared = IsUploadedAssetType(type)
            ? original
            : PrepareRecordingTrackSet({originalTracks: original, assetTitle: title});

        return {exportedEditedTracks: exportedEdited, originalRecordings: prepared};
    }, [tracks, type, title]);

    const [selectedTrackId, setSelectedTrackId] = useState(null);
    const [selectedTrack, setSelectedTrack] = useState(null);

    const handleTrackSelection = (trackId) => {
        const allTracks = [...exportedEditedTracks, ...originalRecordings];
        const selectedTrack = allTracks.find(track => track.trackId === trackId);
        setSelectedTrackId(trackId);
        setSelectedTrack(selectedTrack);
    };

    useEffect(() => {
        if (exportedEditedTracks.length > 0) {
            handleTrackSelection(exportedEditedTracks[0].trackId);
        } else if (originalRecordings.length > 0) {
            handleTrackSelection(originalRecordings[0].trackId);
        }
    }, [exportedEditedTracks, originalRecordings]);

    const videoPlayerArea = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: "78px",
    }

    useEffect(() => {
        const getAsset = async () => {
            console.log("Getting asset: ", assetId)
            try {
                const {data} = await fetchContext.authAxios.get(`/shared/asset/${assetId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Data: ", data);
                setAssetData({
                    error: null,
                    isLoaded: true,
                    title: data.name,
                    createTime: data.createTime,
                    tracks: data.tracks,
                    type: data.type
                });
                setSelectedTrack(data.tracks[0]);
                setSelectedTrackId(data.tracks[0].trackId);
            } catch (error) {
                setAssetData({error: error, isLoaded: true, title: '', createTime: 0, tracks: []});
            }
        }
        getAsset();
    }, [assetId, token]);

    const handleCardClick = (item) => {
        handleTrackSelection(item.trackId)
    }

    const onInfoClick = () => {
        navigate('/');
    }

    return (
        <Box sx={pageStyle}>
            {!isMobile && <Box sx={announcementBarStyle}>
            <Typography sx={announcementTextStyle}>
                This is a shared view of an asset on Libretto.{' '}
                <Link
                    component="span"
                    onClick={onInfoClick}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                >
                    Sign up
                </Link>
                {' '}to access more features.
            </Typography>
        </Box>}
            <Box sx={navBarStyle}>
                <Box style={logoBoxStyle} onClick={() => navigate("/")}>
                    <LogoIcon/>
                </Box>
            </Box>
            <Dialog
                open={isMobile}
                onClose={() => setSwitchToComputerFormOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: {xs: '300px', md: '400px'},
                }}>
                    <Typography><b>Please switch to your computer.</b> For now, you need a larger screen to view
                        this.</Typography>
                </DialogContent>
            </Dialog>
            <Box sx={videoPlayerArea}>
                <video controls={true} style={{height: "55%", maxHeight: "550px", width: "60%", maxWidth: "1000px", borderRadius: "20px"}}
                    src={selectedTrack ? selectedTrack.renditionUrl ? selectedTrack.renditionUrl : selectedTrack.objectUrl : ""}/>
            </Box>
            <AssetAndTrackDetails assetTitle={title} assetCreatedAt={createTime}
                                  trackTitle={selectedTrack ? selectedTrack.title : ""}
                                  trackCreatedAt={selectedTrack ? selectedTrack.createTime : ""}/>
            {exportedEditedTracks.length > 0 && !isMobile &&
                <Box sx={{marginTop: "40px"}}>
                    <Box style={titleStyle}>
                        Exported edits
                    </Box>
                    <Grid item>
                        {exportedEditedTracks.map((item, i) => (
                            <ListViewUploadedTrack track={item} onClick={() => handleCardClick(item)}
                                                   onRenameClick={null} onDeleteClick={null}
                                                   isSelected={item.trackId === selectedTrackId} isSharedView={true}/>
                        ))}
                    </Grid>
                </Box>}
            {originalRecordings.length > 0 && !isMobile &&
                <OriginalTracksView originalTracks={originalRecordings} onClick={handleCardClick} assetType={type}
                                    assetTitle={title} onRenameClick={null} onDeleteClick={null}
                                    selectedTrackId={selectedTrackId} isSharedView={true}/>}
        </Box>
    )
}

export default NewAsset;
