import Container from '../../components/Container'
import {HEADING} from '@lexical/markdown'
import {
    LexicalComposer,
} from '@lexical/react/LexicalComposer'
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {
    $createParagraphNode, $createTextNode,
    $getNodeByKey, $getRoot,
    $getSelection, $nodesOfType, $setSelection,
} from 'lexical'
import {debounce} from 'lodash-es'
import {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react'
import {
    processLocations,
} from '../Editor/AudioEngine'
import {$createSoundNode, $isSoundNode, SoundNode} from '../Editor/SoundNode'
import {SilenceInfoCard, EditSelectedCard, CorrectTextBox} from "../Editor/EditSelectedCard";
import {NodeEventPlugin} from "@lexical/react/LexicalNodeEventPlugin";
import {AppendWordsToRoot, CreateEditorState, OptimizeFillerWordRemovalTrim, ParseTranscriptWords} from "../Editor/Transcript";
import {Typography} from "@mui/material";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {autorun, reaction} from "mobx";
import {ApplicationStore} from "../../editor/store/ApplicationStore";
import {
    calculateSoundNodeTimestamp,
    CreateMultiTrackEditorState,
    dotsForDuration,
    zeroIfNull
} from "./MultitrackTranscript";
import {loadTranscriptForTrack} from "../../utils/utils";
import {AuthContext} from "../../context/AuthContext";
import {FetchContext} from "../../context/FetchContext";
import {ClipTypeEnum} from "@rendley/sdk";

function RefPlugin({editorRef}) {
    const [editor] = useLexicalComposerContext()
    editorRef.current = editor
    return null
}

const ClipsToTimelineItems = (clips, mediaToEntityIdMap) => {
    // Clips are maps whose values are Clip objects. For each clip object, do clip.mediaDataId and use that
    // to get the entity id which you can use to create the timeline items, which are of the form
    // {id: entityId, position: clip.leftTrim + clip.startTime, startTime: clip.leftTrim, endTime: clip.duration - clip.rightTrim}

    const timelineItems = [];

    Object.entries(clips).forEach(([clipId, clip]) => {
        const mediaDataId = clip.mediaDataId;
        const entityId = mediaToEntityIdMap ? mediaToEntityIdMap.get(mediaDataId) : null;
        if (entityId) {
            timelineItems.push({
                trackId: entityId,
                position: clip.leftTrim + clip.startTime,
                startTime: clip.leftTrim,
                endTime: clip.duration - clip.rightTrim
            });
        }
    });

    return timelineItems;
}

const NewTextEditor = ({
                                     editorRef,
                                     onSelect,
                                     mediaIdToEntityIdMap,
                                 }) => {

    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const [isPlaying, setIsPlaying] = useState(RendleyStore.isPlaying);
    const [currentTime, setCurrentTime] = useState(RendleyStore.currentTime);
    const [selectedClipId, setSelectedClipId] = useState(ApplicationStore.selectedClipId);
    const [layers, setLayers] = useState(RendleyStore.layers);
    const [clips, setClips] = useState([]);
    const [transcripts, setTranscripts] = useState(new Map());
    const [draggingObject, setDraggingObject] = useState(null);
    const [trackIdSet, setTrackIdSet] = useState(new Set());
    const [transcriptsLoaded, setTranscriptsLoaded] = useState(false);

    const editorState = CreateMultiTrackEditorState({timelineState: ClipsToTimelineItems(clips, mediaIdToEntityIdMap), transcripts});

    useEffect(() => {
        // Reaction that listens for specific changes in RendleyStore and ApplicationStore
        const dispose = reaction(
            () => ({
                isPlaying: RendleyStore.isPlaying,
                selectedClipId: ApplicationStore.selectedClipId,
                currentTime: RendleyStore.currentTime,
                layers: RendleyStore.layers,
                clips: RendleyStore.clips,
                draggingObject: ApplicationStore.draggingObject,
            }),
            (state) => {
                console.log("Re-rendering text editor");

                // Update the respective React states with the observed values
                setIsPlaying(state.isPlaying);
                setSelectedClipId(state.selectedClipId);
                setCurrentTime(state.currentTime);
                setLayers(state.layers);
                setClips(state.clips);
                setDraggingObject(state.draggingObject);
            }
        );

        // Cleanup function to dispose of the reaction when the component unmounts
        return () => {
            dispose();
        };
    }, []);

    useEffect(() => {
        // Clips is a map whose values are Clip objects. For each clip object, do clip.mediaDataId and use that
        // to get the entity ids which you can collect in a set.
        if (!clips || !mediaIdToEntityIdMap) {
            return;
        }

        const newTrackIdSet = new Set();
        Object.entries(clips).forEach(([clipId, clip]) => {
            const mediaId = clip.mediaDataId;
            if (mediaIdToEntityIdMap.has(mediaId) && (clip.type === ClipTypeEnum.VIDEO || clip.type === ClipTypeEnum.AUDIO)) {
                newTrackIdSet.add(mediaIdToEntityIdMap.get(mediaId));
            }
        });
        setTrackIdSet(newTrackIdSet);
    }, [clips, mediaIdToEntityIdMap]);

    useEffect(() => {
        const loadAllTranscripts = async () => {
            const trackIdsArray = Array.from(trackIdSet);
            const transcriptPromises = trackIdsArray.map(trackId => loadTranscriptForTrack({
                trackId,
                authContext,
                fetchContext
            }));

            try {
                const transcriptsData = await Promise.all(transcriptPromises);

                const newTranscripts = new Map();
                trackIdsArray.forEach((trackId, index) => {
                    newTranscripts.set(trackId, transcriptsData[index]);
                });

                setTranscripts(newTranscripts);
                setTranscriptsLoaded(true);
            } catch (error) {
                console.error("Error loading transcripts:", error);
            }
        };

        console.log("Attempting to load transcripts")

        if (trackIdSet.size > 0) {
            console.log("Loading transcripts")
            loadAllTranscripts();
        }
    }, [trackIdSet, authContext, fetchContext]);


    useEffect(() => {
        console.log("Recreate editor state being attempted")

        if (editorRef.current) {
            console.log("Recreating editor state")
            editorRef.current.recreateEditorState(ClipsToTimelineItems(clips, mediaIdToEntityIdMap), transcripts);
        }
    }, [transcripts, clips]);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.updateCurrentTime(currentTime, 0);
        }
    }, [currentTime, editorRef.current]);

    if (transcriptsLoaded) {
        return (
            <TextEditor ref={editorRef}
                        onSelect={onSelect} editorState={editorState}/>
        )
    }

    return (
        <Typography variant="h6">Loading text editor</Typography>
    )
}

const TextEditor = forwardRef(function Editor(
    {
        onSelect,
        editorState,
    },
    ref,
) {
    const editorRef = useRef(null)
    const [showEditCard, setShowEditCard] = useState(false);
    const [editCardPosition, setEditCardPosition] = useState({x: null, y: null});
    const [isEditSpeakerNode, setIsEditSpeakerNode] = useState(false);
    const [showUndeleteOption, setShowUndeleteOption] = useState(false);
    const [showCorrectOption, setShowCorrectOption] = useState(false);
    const [showSilenceInfoCard, setShowSilenceInfoCard] = useState(false);
    const [silenceInfoCardPosition, setSilenceInfoCardPosition] = useState({x: null, y: null});
    const [silenceInfoDuration, setSilenceInfoDuration] = useState(0);
    const [showCorrectTextBox, setShowCorrectTextBox] = useState(false);
    const [textBoxWidth, setTextBoxWidth] = useState(0);
    const [correctTextBoxPosition, setCorrectTextBoxPosition] = useState({x: null, y: null});
    const [correctTextNodeKey, setCorrectTextNodeKey] = useState(null);
    const [correctedText, setCorrectedText] = useState("");
    const [selectedNodes, setSelectedNodes] = useState([]);

    const [currentNodeElement, setCurrentNodeElement] = useState(null);
    const previousNodeElementRef = useRef(null);

    useEffect(() => {

        if (currentNodeElement) {
            if (currentNodeElement.style.textDecoration !== 'line-through') {
                currentNodeElement.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
            }
        }

        if (previousNodeElementRef.current && previousNodeElementRef.current.style.textDecoration !== 'line-through') {
            previousNodeElementRef.current.style.backgroundColor = 'transparent';
        }
        previousNodeElementRef.current = currentNodeElement;

    }, [currentNodeElement]);


    const saveCorrection = () => {};
    const saveTrim = () => {};
    const undoTrim = () => {};
    const undoFillerWordRemovalTrim = () => {};

    const initialConfig = {
        namespace: 'Libretto',
        theme: {
            ltr: 'ltr',
            rtl: 'rtl',
            placeholder: 'editor-placeholder',
            paragraph: 'editor-paragraph',
        },
        editable: false,
        onError: (err) => {
            console.error('Error:', err)
        },
        nodes: [SoundNode, HeadingNode],
        editorState: editorState,
    };

    function getAllSoundNodes() {
        return (
            editorRef.current?.getEditorState().read(() => {
                return $nodesOfType(SoundNode)
            }) ?? []
        )
    }

    function getFillerWordTrims() {
        return (
            editorRef.current?.getEditorState().read(() => {
                const allNodes = getAllSoundNodes();
                const fillerWordNodes = allNodes.filter((n) => n.isFillerWord());
                const fillerWordTrims = new Map();

                // Helper function to get the next non-filler node
                function getNextNonFillerNode(currentIndex) {
                    for (let i = currentIndex + 1; i < allNodes.length; i++) {
                        if (!allNodes[i].isFillerWord()) {
                            return allNodes[i];
                        }
                    }
                    return null;
                }

                // Helper function to get the previous non-filler node
                function getPreviousNonFillerNode(currentIndex) {
                    for (let i = currentIndex - 1; i >= 0; i--) {
                        if (!allNodes[i].isFillerWord()) {
                            return allNodes[i];
                        }
                    }
                    return null;
                }

                for (let i = 0; i < fillerWordNodes.length; i++) {
                    const fillerWordNode = fillerWordNodes[i];
                    const orderedIndex = String(fillerWordNode.getOrderedTrackIndex());
                    const nextNonFillerNode = getNextNonFillerNode(allNodes.indexOf(fillerWordNode));
                    const prevNonFillerNode = getPreviousNonFillerNode(allNodes.indexOf(fillerWordNode));

                    if (nextNonFillerNode && prevNonFillerNode &&
                        nextNonFillerNode.getOrderedTrackIndex() === fillerWordNode.getOrderedTrackIndex() &&
                        prevNonFillerNode.getOrderedTrackIndex() === fillerWordNode.getOrderedTrackIndex()) {

                        let prevEndTime = prevNonFillerNode.isSilenceNode() ? prevNonFillerNode.getEnd() - prevNonFillerNode.getDuration()/2 : prevNonFillerNode.getEnd();
                        let nextStartTime = nextNonFillerNode.isSilenceNode() ? nextNonFillerNode.getStart() + nextNonFillerNode.getDuration()/2 : nextNonFillerNode.getStart();

                        const newTrim = {
                            startTime: Math.min(prevEndTime, fillerWordNode.getStart()),
                            endTime: Math.max(nextStartTime, fillerWordNode.getEnd())
                        };

                        const improvedTrim = OptimizeFillerWordRemovalTrim(newTrim);

                        if (fillerWordTrims.has(orderedIndex)) {
                            const trims = fillerWordTrims.get(orderedIndex);
                            trims.push(improvedTrim);
                            fillerWordTrims.set(orderedIndex, trims);
                        } else {
                            const trims = [improvedTrim];
                            fillerWordTrims.set(orderedIndex, trims);
                        }

                    } else {
                        const newTrim = {
                            startTime: fillerWordNode.getStart(),
                            endTime: fillerWordNode.getEnd()
                        };

                        const improvedTrim = OptimizeFillerWordRemovalTrim(newTrim);

                        if (fillerWordTrims.has(orderedIndex)) {
                            const trims = fillerWordTrims.get(orderedIndex);
                            trims.push(improvedTrim);
                            fillerWordTrims.set(orderedIndex, trims);
                        } else {
                            const trims = [improvedTrim];
                            fillerWordTrims.set(orderedIndex, trims);
                        }
                    }
                }

                return fillerWordTrims;
            }) ?? new Map()
        )
    }

    useImperativeHandle(
        ref,
        () =>
            ({
                getEditor() {
                    return editorRef.current
                },
                processTrimEdit(key) {
                    editorRef.current?.update(() => {
                        const element = editorRef.current.getElementByKey(key);
                        if (element) {
                            element.style.textDecoration = 'line-through';
                            element.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
                        }
                    });
                },
                recreateEditorState(timelineState, transcripts) {
                    console.log("RECREATE CALLED")
                    if (!editorRef.current) {
                        console.log("EDITOR REF NOT FOUND")
                        return;
                    }

                    editorRef.current?.update(() => {
                        console.log("UPDATING EDITOR STATE")
                        const root = $getRoot()
                        root.clear();

                        for (const timelineItem of timelineState) {
                            const trackId = timelineItem.trackId;

                            console.log("Creating editor state for trackId: ", trackId)

                            const transcriptJson = transcripts.get(trackId);
                            if (!transcriptJson) {
                                console.log("Transcript json not found for trackId: ", trackId)
                                continue;
                            }

                            console.log("Transcript json found for trackId: ", transcriptJson)

                            // Iterate through the words in the transcript.
                            // We'll only keep words that are within the timelineItem's start and end time.
                            // The timestamps of the soundnodes will be offset back by the timelineItem's start time, and offset
                            // forward by the timelineItem's position.

                            const allWords = ParseTranscriptWords(transcriptJson);

                            let para = null
                            let lastSource = null
                            let lastEndTime = null

                            let index = 0
                            let speakerIndex = allWords.length + 5

                            for (const word of allWords) {

                                if (word.start < timelineItem.startTime || word.end > timelineItem.endTime) {
                                    continue;
                                }

                                // Create a new Speaker Name paragraph if the speaker changes.
                                if (lastSource !== word.speaker) {
                                    const speakerNameParagraph = $createParagraphNode()
                                    root.append(speakerNameParagraph)

                                    const speakerNameTextNode = $createSoundNode(`${word.speaker}: `, {source: word.speaker, start: calculateSoundNodeTimestamp({timelineItem, wordTime: word.start}), end: calculateSoundNodeTimestamp({timelineItem, wordTime: word.start}), isSilence: false, isSpeakerNode: true, transcriptIndex: speakerIndex, originalText: undefined, orderedTrackIndex: 0});
                                    const speakerIndexStr = String(speakerIndex);
                                    speakerIndex++;
                                    speakerNameTextNode.toggleFormat('bold');
                                    speakerNameParagraph.append(speakerNameTextNode);

                                    para = $createParagraphNode()
                                    root.append(para)
                                    lastSource = word.speaker
                                }

                                // Add silence nodes if there is a significant gap between the last word and the current word.
                                if (zeroIfNull(lastEndTime) + 0.7 < word.start) {
                                    const dots = dotsForDuration(word.start - zeroIfNull(lastEndTime));
                                    const soundNode = $createSoundNode(dots, {source: word.speaker, start: calculateSoundNodeTimestamp({timelineItem, wordTime:zeroIfNull(lastEndTime)}), end: calculateSoundNodeTimestamp({timelineItem, wordTime:word.start}), isSilence: true, isSpeakerNode: false, transcriptIndex: -1, orderedTrackIndex: 0});
                                    soundNode.toggleFormat('bold');
                                    para.append(soundNode);
                                    para.append($createTextNode(' '))


                                    if (zeroIfNull(lastEndTime) + 2 < word.start) {
                                        para = $createParagraphNode()
                                        root.append(para)
                                        para.append($createTextNode(''))
                                    }
                                }

                                lastEndTime = word.end
                                let soundNodeWord = word.word;
                                const strIndex = String(index);
                                const text = $createSoundNode(soundNodeWord, {source: word.speaker, start: calculateSoundNodeTimestamp({timelineItem, wordTime:word.start}), end: calculateSoundNodeTimestamp({timelineItem, wordTime:word.end}), isSilence: false, isSpeakerNode: false, transcriptIndex: index, originalText: word.word, orderedTrackIndex: 0});
                                para.append(text)

                                if (index !== allWords.length) {
                                    para.append($createTextNode(' '))
                                }
                                index++
                            }
                        }
                    });
                },

                processCorrectionEdit(key, correctedText) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key);
                        if (node && $isSoundNode(node)) {
                            node.setTextContent(correctedText);
                        }
                    });
                },
                processUndeletionEdit(key) {
                    editorRef.current?.update(() => {
                        const element = editorRef.current.getElementByKey(key);
                        if (element && element.style.textDecoration === 'line-through') {
                            element.style.textDecoration = '';
                            element.style.backgroundColor = 'transparent';
                        }
                    });
                },
                updateSoundNode(key, locUpdate) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key)
                        if (!node || !$isSoundNode(node)) {
                            console.warn('Unexpected node', node)
                            return
                        }
                        const newLoc = {...node.getSoundLocation(), ...locUpdate}
                        node.setSoundLocation(newLoc)
                        console.log('updated', newLoc)
                    })
                },
                setSoundNodePlaying(key, isPlaying) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key)
                        if (!node) {
                            console.warn('node not found', key)
                            return
                        }
                        node.setIsPlaying(isPlaying)
                    })
                },
                scrollToKey(key) {
                    const el = editorRef.current?.getElementByKey(key)
                    if (!el) {
                        console.warn('unable to locate element for', key)
                        return
                    }
                    el.scrollIntoView({behavior: 'smooth', block: 'center'})
                },
                updateCurrentTime(time, orderedIndex) {
                    editorRef.current?.update(() => {
                        const allNodes = getAllSoundNodes();
                        const node = allNodes.find((n) => n.getOrderedTrackIndex() === orderedIndex && n.getStart() <= time && n.getEnd() >= time);
                        if (node) {
                            const nodeKey = node.getKey();
                            if (!nodeKey) {
                                return;
                            }
                            const element = editorRef.current.getElementByKey(nodeKey);
                            if (element === null) {
                                return;
                            }
                            setCurrentNodeElement(element);
                            element.scrollIntoView({behavior: 'smooth', block: 'center'});
                        } else {
                            // console.log("Node not found for time: ", time, " and ordered index: ", orderedIndex);
                        }
                    });
                },
                getFillerWordTrims,
                getAllSoundNodes,
            }),
        [],
    )

    const handleSelectionChange = debounce(function handleSelectionChange(
            editorState, editor
        ) {
            editorState.read(() => {
                const selection = $getSelection()

                if (!selection) {
                    onSelect?.([], [])
                    handleNodesSelected([], editor)
                    return
                }

                //console.log("Selection is: ", selection)

                const selectedNodes = selection.getNodes()
                const soundNodes = selectedNodes.filter($isSoundNode)

                const rawLocs = soundNodes.map((l) => l.getSoundLocation())
                const locs = processLocations(rawLocs)


                handleNodesSelected(rawLocs, editor)
                onSelect?.(
                    locs,
                    soundNodes.map((n) => ({...n.exportJSON(), key: n.getKey()})),
                )
            })
        },
        150)

    const editorInputStyle = {
        textAlign: 'justify',
        overflowY: 'auto', // Ensures content is scrollable only vertically
        height: 'calc(100vh - 200px)', // Optional for setting a fixed height
        maxWidth: '820px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '20px',
        scrollbarWidth: 'none',
        outline: 'none',
    };

    const handleSoundNodeMouseOver = (event, editor, nodeKey) => {
        const node = $getNodeByKey(nodeKey);
        // Show silence info card if the node is a silence node.
        if (node && $isSoundNode(node) && node.isSilenceNode()) {
            setSilenceInfoDuration(node.getDuration());
            setSilenceInfoCardPosition({x: event.clientX, y: event.clientY});
            setShowSilenceInfoCard(true);
        }

        const element = editor.getElementByKey(nodeKey);
        // Highlight the element
        if (element.style.textDecoration !== 'line-through') {
            element.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
        }
    }

    const handleSoundNodeMouseOut = (event, editor, nodeKey) => {
        setShowSilenceInfoCard(false);
        const element = editor.getElementByKey(nodeKey);
        // Remove the highlight
        if (element.style.textDecoration !== 'line-through') {
            element.style.backgroundColor = 'transparent';
        }
    }

    // Returns whether the node is deleted or not.
    const underlineNode = (nodeKey, editor) => {
        const element = editor.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        if (element.style.textDecoration === '') {
            element.style.textDecoration = 'underline';
            return false;
        }
        return element.style.textDecoration === 'line-through';
    }

    const removeUnderlineForNode = (nodeKey, editor) => {
        const element = editor.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        if (element.style.textDecoration === 'underline') {
            element.style.textDecoration = '';
        }
    }

    const handleNodesSelected = (nodes, editor) => {
        // Unselect previously selected nodes
        for (let node of selectedNodes) {
            if (node.key) {
                removeUnderlineForNode(node.key, editor);
            }
        }
        setSelectedNodes(nodes);
        if (!nodes || nodes.length === 0) {
            setShowEditCard(false);
            return;
        }

        // Determine the trim of the selected nodes.
        let firstNodeStartTime = nodes[0].start;
        let lastNodeEndTime = nodes[nodes.length - 1].end;

        // console.log("Selected trim with start: ", firstNodeStartTime, " and end: ", lastNodeEndTime)

        // Underline the selected nodes.
        let allDeleted = true;
        for (let node of nodes) {
            if (node.key) {
                const deleted = underlineNode(node.key, editor);
                if (!deleted) {
                    allDeleted = false;
                }
                const fetchedNode = $getNodeByKey(node.key);
                // If the selected node is a speaker node and there are more than one node selected, don't show the edit card.
                if (fetchedNode && fetchedNode.isSpeakerNode() && nodes.length > 1) {
                    return;
                }
            }
        }

        // Use the location of the first node to position the edit card.
        const firstNodeKey = nodes[0].key;
        const element = editor.getElementByKey(firstNodeKey);
        if (element === null) {
            return;
        }
        const rect = element.getBoundingClientRect();
        const xCoordinate = rect.left + window.scrollX; // X coordinate relative to the document
        const yCoordinate = rect.top + window.scrollY;  // Y coordinate relative to the document

        const firstNode = $getNodeByKey(firstNodeKey);
        const shouldShowCorrectOption = firstNode ? nodes.length === 1 && firstNode != null && !firstNode.isSilenceNode() : false;
        const isSpeakerNode = nodes.length === 1 && firstNode != null && firstNode.isSpeakerNode();
        setShowUndeleteOption(allDeleted && nodes.length > 0);
        setEditCardPosition({x: xCoordinate, y: yCoordinate});
        setIsEditSpeakerNode(isSpeakerNode);
        setShowCorrectOption(shouldShowCorrectOption);
        setShowEditCard(true);
    }

    const handleCorrectSelected = () => {
        if (!selectedNodes || selectedNodes.length !== 1) {
            return;
        }

        const nodeKey = selectedNodes[0].key;
        const element = editorRef.current.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        const rect = element.getBoundingClientRect();
        const xCoordinate = rect.left + window.scrollX; // X coordinate relative to the document
        const yCoordinate = rect.bottom + window.scrollY;
        setCorrectTextBoxPosition({x: xCoordinate, y: yCoordinate});
        let selectedNode = null;
        editorRef.current.update(() => {
            selectedNode = $getNodeByKey(nodeKey);
        });
        setCorrectedText(selectedNode.getTextContent());
        setCorrectTextNodeKey(nodeKey);
        setShowEditCard(false);
        // Get width of html element
        const elementWidth = element.offsetWidth;
        setTextBoxWidth(elementWidth + 23);
        setShowCorrectTextBox(true);
    }

    const handleCorrectBoxClickAway = () => {
        if (correctedText === "") {
            return;
        }

        editorRef.current.update(() => {
            const selectedNode = $getNodeByKey(correctTextNodeKey);
            if (selectedNode) {
                saveCorrection(selectedNode.getOrderedTrackIndex(), selectedNode.getTranscriptIndex(), correctedText);
            }
        });
        setShowCorrectTextBox(false);
    }

    const handleDeleteSelected = (shouldUndelete) => {
        if (!selectedNodes || selectedNodes.length === 0) {
            return;
        }

        editorRef.current.update(() => {
            let nodeKeys = [];
            for (let node of selectedNodes) {
                if (node.key) {
                    nodeKeys.push(node.key);
                }
                if (node.children) {
                    for (let childNode of node.children) {
                        if (childNode.key) {
                            nodeKeys.push(childNode.key);
                        }
                    }
                }
            }

            const trimsWithOrderedIndices = new Map();
            for (let nodeKey of nodeKeys) {
                const node = $getNodeByKey(nodeKey);
                const orderedIndex = node.getOrderedTrackIndex();
                if (trimsWithOrderedIndices.has(orderedIndex)) {
                    const trim = trimsWithOrderedIndices.get(orderedIndex);
                    const newTrim = {
                        startTime: Math.min(trim.startTime, node.getSoundLocation().start),
                        endTime: Math.max(trim.endTime, node.getSoundLocation().end)
                    };
                    trimsWithOrderedIndices.set(orderedIndex, newTrim);
                } else {
                    trimsWithOrderedIndices.set(orderedIndex, {
                        startTime: node.getSoundLocation().start,
                        endTime: node.getSoundLocation().end
                    });
                }
            }

            trimsWithOrderedIndices.forEach((trim, orderedIndex) => {
                if (shouldUndelete) {
                    undoTrim(trim, orderedIndex);
                    undoFillerWordRemovalTrim(trim, orderedIndex);
                } else {
                    saveTrim(trim, orderedIndex);
                }
            });


            setSelectedNodes([])
            $setSelection(null);
        });

        setShowEditCard(false);
    }


    const trimForTrackIndex = (index) => {
        const strIndex = String(index);
        return [];
    }

    const handleSoundNodeClick = (event, editor, nodeKey) => {
        const node = $getNodeByKey(nodeKey);

        if (!node || node.isDeleted()) {
            return;
        }

        const orderedIndex = node.getOrderedTrackIndex();

        const element = editor.getElementByKey(nodeKey);
        setCurrentNodeElement(element);

        const soundNodeTime = node.getStart();
        let timeToSubtract = 0;
        const trimsForTrack = trimForTrackIndex(orderedIndex);

        if (trimsForTrack) {
            for (let trim of trimsForTrack) {
                if (soundNodeTime > trim.endTime) {
                    timeToSubtract += trim.endTime - trim.startTime;
                    continue
                }
            }
        }
        // UpdateVideoPlayerTime(soundNodeTime, orderedIndex);
        // UpdateTimelineEditorTime(soundNodeTime, timeToSubtract, orderedIndex);
        RendleyStore.setCurrentTime(soundNodeTime);
    }

    return (
        <LexicalComposer initialConfig={initialConfig}>
            {/*<Avatars/>*/}
            <Container
                bg="white"
                // my="1"
                py="0"
                px="8"
                fontSize="17px"
                fontWeight="normal"
                style={{overflowY: 'auto', margin: 0, padding: 0, display: 'flex', justifyContent: 'center', backgroundColor: "white",
                    borderRadius: "12px"}}
            >
                <RichTextPlugin
                    contentEditable={
                        <ContentEditable style={editorInputStyle} spellCheck={false}/>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin onChange={handleSelectionChange}/>
                <NodeEventPlugin nodeType={SoundNode} eventType={'mouseover'}
                                 eventListener={handleSoundNodeMouseOver}/>
                <NodeEventPlugin nodeType={SoundNode} eventType={'mouseout'}
                                 eventListener={handleSoundNodeMouseOut}/>
                <NodeEventPlugin nodeType={SoundNode} eventType={'click'} eventListener={handleSoundNodeClick}/>
                <HistoryPlugin/>
                <RefPlugin editorRef={editorRef}/>
                <MarkdownShortcutPlugin transformers={[HEADING]}/>
                {showCorrectTextBox &&
                    <CorrectTextBox xPosition={correctTextBoxPosition.x} yPosition={correctTextBoxPosition.y}
                                    correctedText={correctedText} setCorrectedText={setCorrectedText}
                                    width={textBoxWidth} handleClickaway={handleCorrectBoxClickAway}/>}
                {showSilenceInfoCard &&
                    <SilenceInfoCard xPosition={silenceInfoCardPosition.x} yPosition={silenceInfoCardPosition.y}
                                     durationSecs={silenceInfoDuration}/>}
                {showEditCard && <EditSelectedCard
                    isSpeakerNode={isEditSpeakerNode}
                    selectedNodeX={editCardPosition.x}
                    selectedNodeY={editCardPosition.y}
                    showUndeleteOption={showUndeleteOption}
                    handleDelete={handleDeleteSelected}
                    showCorrectOption={showCorrectOption}
                    handleCorrect={handleCorrectSelected}/>}
            </Container>
        </LexicalComposer>
    )
})


export default NewTextEditor;
