import {alpha, useTheme} from "@mui/material/styles";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import Box from "@mui/material/Box";
import {NewMain} from "../Landing/Components/Main";
import {ModernHero, NewHero} from "../Landing/Components/Hero";
import Container, {NavbarContainer} from "../../components/Container";
import {ModernServices, NewServices} from "../Landing/Components/Services";
import {NewStudioQualityRecording} from "../Landing/Components/QuickStart";
import {NewVideoLayoutFeature} from "../Landing/Components/VideoLayoutFeature";
import {NewLiveSoundboardFeature} from "../Landing/Components/LiveSoundboardFeature";
import {NewEditLikeADocFeature} from "../Landing/Components/EditLikeADocFeature";
import CombineTracksFeature from "../Landing/Components/CombineTracksFeature";
import TrimTracksFeature from "../Landing/Components/TrimTracksFeature";
import {NewAudioEnhanceFeature} from "../Landing/Components/AudioEnhanceFeature";
import ShareRecordingsFeature from "../Landing/Components/ShareRecordingsFeature";
import TranscriptionAndCaptioningFeature from "../Landing/Components/TranscriptionAndCaptioningFeature";
import {NewBenefits} from "../Landing/Components/Benefits";
import {PricingBox} from "../Pricing/Pricing";
import {NewGetStarted} from "../Landing/Components/GetStarted";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ModernOneToolSection, {ModernEffortlessEditingSection} from "../Landing/Components/ModernOneToolSection";

const AnnualPlan = "Annual";
const MonthlyPlan = "Monthly";

const TestimonialSection = () => {
    return (
        <Box sx={{
            height: "570px",
            borderRadius: "20px",
            maxWidth: "1200px",
            width: "100%",
            border: "1px solid #eaeaea",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: "20px",
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: "#edf1f7",
        }}>
            <Box sx={{fontSize: "24px", width: "60%"}}>
                Hundreds of podcasters, video editors, and content creators trust Libretto to simplify their
                workflow and focus on what matters—producing high-quality content.
            </Box>
        </Box>
    );
}


export const ModernLandingPage = () => {
    const theme = useTheme();

    const authContext = useContext(AuthContext);

    const [selectedPricingOption, setSelectedPricingOption] = useState(AnnualPlan);

    return (
        <>
            <Box sx={{overflowX: 'hidden'}}>
                <NewMain bgcolor={'background.paper'} selectedPricingOption={selectedPricingOption} pageEnum={0}>
                    <ModernHero/>
                    <Container>
                        <ModernServices/>
                    </Container>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                            marginTop: theme.spacing(-4),
                        }}
                    >
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-3),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Container sx={{marginTop: "-50px"}}>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    align={'center'}
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Introducing Libretto
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="p"
                                    fontFamily={"Inter"}
                                    color="text.secondary"
                                    sx={{ fontWeight: 400 }}
                                    align={'center'}
                                >
                                    Recording and editing made simple.
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                    <Container sx={{marginTop: "-80px"}}>
                        <TestimonialSection/>
                    </Container>
                    <Container sx={{marginTop: "-30px"}}>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    align={'center'}
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    One tool to create all of this
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="p"
                                    fontFamily={"Inter"}
                                    color="text.secondary"
                                    sx={{ fontWeight: 400 }}
                                    align={'center'}
                                >
                                    Meet your audiences wherever they are.
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                    <NavbarContainer sx={{marginTop: "-100px"}}>
                        <ModernOneToolSection/>
                    </NavbarContainer>
                    <Container sx={{marginTop: "-30px"}}>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Box>
                                <Typography
                                    variant="h2"
                                    color="text.primary"
                                    align={'center'}
                                    gutterBottom
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                >
                                    Effortless editing
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                    <NavbarContainer sx={{marginTop: "-100px"}}>
                        <ModernEffortlessEditingSection/>
                    </NavbarContainer>

                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >
                        <Container>
                            <NewStudioQualityRecording/>
                        </Container>
                        <Container>
                            <NewVideoLayoutFeature/>
                        </Container>
                        <Container>
                            <NewLiveSoundboardFeature/>
                        </Container>
                        <Container>
                            <NewEditLikeADocFeature/>
                        </Container>
                        {/*<Container>*/}
                        {/*    <CombineTracksFeature/>*/}
                        {/*</Container>*/}
                        {/*<Container>*/}
                        {/*    <TrimTracksFeature/>*/}
                        {/*</Container>*/}
                        <Container>
                            <NewAudioEnhanceFeature/>
                        </Container>
                        <Container>
                            <ShareRecordingsFeature/>
                        </Container>
                        {/*<Container>*/}
                        {/*    <TranscriptionAndCaptioningFeature/>*/}
                        {/*</Container>*/}
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-3),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >
                        <Container>
                            <NewBenefits/>
                        </Container>
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-2),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Container>
                        <NewGetStarted handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                    </Container>
                </NewMain>
            </Box>
        </>
    );
};

const NewLandingPage = () => {
    const theme = useTheme();

    const authContext = useContext(AuthContext);

    const [selectedPricingOption, setSelectedPricingOption] = useState(AnnualPlan);

    return (
        <>
            <Box sx={{overflowX: 'hidden'}}>
                <NewMain bgcolor={'background.paper'} selectedPricingOption={selectedPricingOption} pageEnum={0}>
                    <NewHero/>
                    <Container>
                        <NewServices/>
                    </Container>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >
                        <Container>
                            <NewStudioQualityRecording/>
                        </Container>
                        <Container>
                            <NewVideoLayoutFeature/>
                        </Container>
                        <Container>
                            <NewLiveSoundboardFeature/>
                        </Container>
                        <Container>
                            <NewEditLikeADocFeature/>
                        </Container>
                        {/*<Container>*/}
                        {/*    <CombineTracksFeature/>*/}
                        {/*</Container>*/}
                        {/*<Container>*/}
                        {/*    <TrimTracksFeature/>*/}
                        {/*</Container>*/}
                        <Container>
                            <NewAudioEnhanceFeature/>
                        </Container>
                        <Container>
                            <ShareRecordingsFeature/>
                        </Container>
                        {/*<Container>*/}
                        {/*    <TranscriptionAndCaptioningFeature/>*/}
                        {/*</Container>*/}
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-3),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            backgroundImage: `linear-gradient(to bottom, ${alpha(
                                theme.palette.background.paper,
                                0,
                            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                            backgroundRepeat: 'repeat-x',
                            position: 'relative',
                        }}
                    >
                        <Container>
                            <NewBenefits/>
                        </Container>
                        <Box
                            component={'svg'}
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                            sx={{
                                width: '100%',
                                marginBottom: theme.spacing(-2),
                            }}
                        >
                            <path
                                fill={theme.palette.background.paper}
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </Box>
                    </Box>
                    <Container>
                        <NewGetStarted handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                    </Container>
                </NewMain>
            </Box>
        </>
    );
};

export default NewLandingPage;
