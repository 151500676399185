
// Timeline state is a list of timelineItems where TimelineItem is an object with the following properties:
// TrackId, Position, StartTime, EndTime (in seconds)
// Transcripts is a map where the key is the trackId and the value is the transcriptJson.
import {$createParagraphNode, $createTextNode, $getRoot} from "lexical";
import {ParseTranscriptWords} from "../Editor/Transcript";
import {$createSoundNode} from "../Editor/SoundNode";

export const calculateSoundNodeTimestamp = ({timelineItem, wordTime}) => {
    return wordTime - timelineItem.startTime + timelineItem.position;
}

export const zeroIfNull = (lastEndTime) => {
    return lastEndTime === null ? 0 : lastEndTime;
}

export const dotsForDuration = (duration) => {
    // one dot for durations under 1 second
    // two dots for durations greater than 1 second
    return duration < 1 ? '\u00B7' : '\u00B7\u00B7';
}

// TimelineState is a list of timelineItem objects
// TimelineItem is an object with the following properties:
// trackId, position, startTime, endTime (in seconds)
// TimelineItems correspond to "Clips" in Rendley terminology.
export const CreateMultiTrackEditorState = ({timelineState, transcripts}) => {
    // Assume that the timelineState is sorted by position

    return () => {
        const root = $getRoot()
        root.clear();

        for (const timelineItem of timelineState) {
            const trackId = timelineItem.trackId;
            const transcriptJson = transcripts.get(trackId);
            if (!transcriptJson) {
                continue;
            }

            // Iterate through the words in the transcript.
            // We'll only keep words that are within the timelineItem's start and end time.
            // The timestamps of the soundnodes will be offset back by the timelineItem's start time, and offset
            // forward by the timelineItem's position.

            const allWords = ParseTranscriptWords(transcriptJson);

            let para = null
            let lastSource = null
            let lastEndTime = null

            let index = 0
            let speakerIndex = allWords.length + 5

            for (const word of allWords) {

                if (word.start < timelineItem.startTime || word.end > timelineItem.endTime) {
                    continue;
                }

                // Create a new Speaker Name paragraph if the speaker changes.
                if (lastSource !== word.speaker) {
                    const speakerNameParagraph = $createParagraphNode()
                    root.append(speakerNameParagraph)

                    const speakerNameTextNode = $createSoundNode(`${word.speaker}: `, {source: word.speaker, start: calculateSoundNodeTimestamp({timelineItem, wordTime: word.start}), end: calculateSoundNodeTimestamp({timelineItem, wordTime: word.start}), isSilence: false, isSpeakerNode: true, transcriptIndex: speakerIndex, originalText: undefined, orderedTrackIndex: 0});
                    const speakerIndexStr = String(speakerIndex);
                    speakerIndex++;
                    speakerNameTextNode.toggleFormat('bold');
                    speakerNameParagraph.append(speakerNameTextNode);

                    para = $createParagraphNode()
                    root.append(para)
                    lastSource = word.speaker
                }

                // Add silence nodes if there is a significant gap between the last word and the current word.
                if (zeroIfNull(lastEndTime) + 0.7 < word.start) {
                    const dots = dotsForDuration(word.start - zeroIfNull(lastEndTime));
                    const soundNode = $createSoundNode(dots, {source: word.speaker, start: calculateSoundNodeTimestamp({timelineItem, wordTime:zeroIfNull(lastEndTime)}), end: calculateSoundNodeTimestamp({timelineItem, wordTime:word.start}), isSilence: true, isSpeakerNode: false, transcriptIndex: -1, orderedTrackIndex: 0});
                    soundNode.toggleFormat('bold');
                    para.append(soundNode);
                    para.append($createTextNode(' '))


                    if (zeroIfNull(lastEndTime) + 2 < word.start) {
                        para = $createParagraphNode()
                        root.append(para)
                        para.append($createTextNode(''))
                    }
                }

                lastEndTime = word.end


                let soundNodeWord = word.word;
                const strIndex = String(index);
                // if (strIndex in corrections) {
                //     const correctedText = corrections[strIndex].correctedText;
                //     soundNodeWord = correctedText;
                // }

                const text = $createSoundNode(soundNodeWord, {source: word.speaker, start: calculateSoundNodeTimestamp({timelineItem, wordTime:word.start}), end: calculateSoundNodeTimestamp({timelineItem, wordTime:word.end}), isSilence: false, isSpeakerNode: false, transcriptIndex: index, originalText: word.word, orderedTrackIndex: 0});


                // for (let trim of trims) {
                //     if (word.start >= trim.startTime && word.end <= trim.endTime) {
                //         text.setDeleted();
                //     }
                // }
                //
                // for (let trim of fillerWordRemovalTrims) {
                //     if (word.start >= trim.startTime && word.end <= trim.endTime) {
                //         text.setRemovedAsFillerWord();
                //     }
                // }

                para.append(text)

                if (index !== allWords.length) {
                    para.append($createTextNode(' '))
                }
                index++
            }
        }
    }
}