import React from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { Crop169, CropPortrait, CropSquare } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Card, CardContent, ClickAwayListener } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {
    ClipSize16And9RatioId,
    ClipSize1And1RatioId,
    ClipSize9And16RatioId,
    ClipSizeToWidthAndHeight
} from "../../utils/utils";
import { Engine } from "@rendley/sdk";
import {calculateAspectRatioFit} from "../../editor/utils/math/calculateAspectRatioFit";
import {WindowStore} from "../../editor/store/WindowStore";

const AspectRatioSelectCard = observer(({containerRef, canvasRef, handleClose, setAspectRatioId, aspectRatioId, topPosition, leftPosition, guestEditor }) => {

    const handleClipSizeChange = (clipSizeId) => {
        const {width, height} = ClipSizeToWidthAndHeight(clipSizeId);
        Engine.getInstance().getDisplay().setResolution(width, height);
        const {newHeight, newWidth} = calculateWidthForComposition({containerRef, canvasRef});

        if (canvasRef.current) {
            canvasRef.current.style.width = `${newWidth}px`;
            canvasRef.current.style.height = `${newHeight}px`;
        }
        const {clientWidth: containerWidth, clientHeight: containerHeight} = containerRef.current;
        WindowStore.setCanvasResolution([containerWidth, containerHeight]);
        WindowStore.setResolution([newWidth, newHeight]);

        setAspectRatioId(clipSizeId);
    }

    const calculateWidthForComposition = ({containerRef, canvasRef}) => {
        if (!containerRef.current || !canvasRef.current) return { width: 0, height: 0 };

        const { clientWidth: containerWidth, clientHeight: containerHeight } = containerRef.current;
        const { width: canvasWidth, height: canvasHeight } = canvasRef.current;

        return calculateAspectRatioFit(canvasWidth, canvasHeight, containerWidth, containerHeight);
    };

    const featureDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
    };

    const ClipSizeSelectBox = ({ inputClipSize, ratioText, clipSizeIcon, surfacesText, disabled }) => {
        return (
            <Box onClick={() => {
                if (disabled) return;
                handleClipSizeChange(inputClipSize)
            }} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingX: 1,
                marginBottom: 2,
                cursor: disabled ? 'not-allowed' : 'pointer',
                transition: 'background-color 0.3s, border 0.3s',
                border: '1px solid transparent',
                borderRadius: '8px',
                '&:hover': {
                    border: '1px solid',
                    borderColor: disabled ? 'transparent' : '#2B6BFD',
                    borderRadius: '8px',
                },
            }}>
                <Box flexDirection="row" display="flex" justify gap={1}>
                    {clipSizeIcon}
                    <Box flexDirection="column">
                        <Typography variant="subtitle2" color="text.primary" fontSize="1.0rem" fontWeight={800}>{ratioText}</Typography>
                        <Box sx={featureDescStyle}>{surfacesText}</Box>
                    </Box>
                </Box>
                {aspectRatioId === inputClipSize && <CheckIcon sx={{fontSize: '1.5rem', color: 'green'}}/>}
            </Box>
        )
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Card
                elevation={5}
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: "70px",
                    // Put it in the middle of the screen
                    left: "calc(50% - 180px)",
                    width: 275,
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    borderRadius: '16px',
                    boxShadow: '0px 30px 80px 0px rgba(0, 0, 0, 0.15)',
                    overflow: 'visible',
                    height: 140,
                    zIndex: 9999
                }}
            >
                <CardContent>
                    <ClipSizeSelectBox
                        inputClipSize={ClipSize16And9RatioId}
                        clipSizeIcon={<Crop169 sx={{color: "#2B6BFD"}}/>}
                        ratioText="16:9"
                        surfacesText="YouTube, Spotify"
                    />
                    {/*<ClipSizeSelectBox*/}
                    {/*    inputClipSize={ClipSize9And16RatioId}*/}
                    {/*    clipSizeIcon={<CropPortrait sx={{color: "#888888"}}/>}*/}
                    {/*    ratioText="9:16"*/}
                    {/*    surfacesText="Shorts, Reels, TikToks"*/}
                    {/*    disabled={true}*/}
                    {/*/>*/}
                    <ClipSizeSelectBox
                        inputClipSize={ClipSize1And1RatioId}
                        clipSizeIcon={<CropSquare sx={{color: "#2B6BFD"}}/>}
                        ratioText="1:1"
                        surfacesText="LinkedIn, X, Instagram"
                    />
                </CardContent>
            </Card>
        </ClickAwayListener>
    )
});

export default AspectRatioSelectCard;
